import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { switchProp } from 'styled-tools';
import {
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT,
  HOT_SEATS
} from 'utils/constants';
import { Button } from 'reactstrap';
import PromotionType from 'components/PromotionType';

const propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

const StyledEventCard = styled.section`
  background: #fff;
  border-radius: 5px;
  margin: 0.75em;
  padding: 0.75em;
  box-shadow: 0.2em 0.5em 0.4em rgba(0, 0, 0, 0.1);
  display: flex;
  height: 12em;
  overflow: hidden;

  border-left: 0.75em solid ${props =>
    switchProp('type', {
      [PROMOTION_EVENT]: props.theme.colors.promotionEvent,
      [SPECIAL_EVENT]: props.theme.colors.specialEvent,
      [ENTERTAINMENT_EVENT]: props.theme.colors.entertainmentEvent
    })}
  };

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h5 {
    color: ${props => props.theme.colors.black}
    font-size: 1em;
    max-height: 2.5em;
    overflow: hidden;
    flex: 0 1 auto;
  }

  button {
    padding-left: 1.5em;
    padding-right: 1.5em;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.7em;
    box-shadow: 0.2em 0.5em 0.4em rgba(0, 0, 0, 0.1);
  }

  p {
    font-size: 0.8em;
    margin-bottom: 0.5em;
    max-height: 3em;
    overflow: hidden;

    &:last-child {
      margin-bottom: 1em;
    }
  }
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  p:first-child {
    flex: 1 0 100%;
  }
`;

const ImageContainer = styled.div`
  background: #eee;
  width: 8em;
  margin-right: 0.75em;
  overflow: hidden;
  flex: 1 0 auto;
  width: 40%;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
  }
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 0 1 auto;
`;

const EventCopy = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1 0 auto;
`;

function EventCard({ title, type }) {
  return (
    <StyledEventCard type={type}>
      <ImageContainer>
        <img src="https://i.imgur.com/CMgTEfZ.jpg" alt="bird" />
      </ImageContainer>
      <EventContent>
        <h5>{title}</h5>
        <EventCopy>
          <p>Win up to $500 CASH or $500 FREE Play!</p>
          <p>
            Mondays in February &middot; 12pm - 3pm
            <br />
            Excludes February 18
          </p>
        </EventCopy>
        <DetailsContainer>
          <PromotionType type={HOT_SEATS} />
          <Button color="secondary" size="sm">
            Details
          </Button>
        </DetailsContainer>
      </EventContent>
    </StyledEventCard>
  );
}

EventCard.propTypes = propTypes;

export default EventCard;
