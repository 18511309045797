/*
 * AppReducer
 *
 * The reducer takes care of our data. Using actions, we can change our application state.
 * To add a new action, add it to the ACTION_HANDLERS object.
 */
import moment from 'moment';

const monthStartMoment = moment();
const monthEndMoment = monthStartMoment.clone().endOf('month');

const monthStart = moment().isBetween(monthStartMoment, monthEndMoment, 'day')
  ? moment().format('YYYY-MM-DD')
  : monthStartMoment.format('YYYY-MM-DD');

const monthEnd = monthEndMoment.format('YYYY-MM-DD');

// The initial state of the App
const initialState = {
  calendarQueryInput: {
    startDate: monthStart,
    endDate: monthEnd
  }
};

// Actions
const SET_CALENDAR_QUERY_INPUT = 'app/Calendar/SET_CALENDAR_QUERY_INPUT';

// Action Handlers
const ACTION_HANDLERS = {
  [SET_CALENDAR_QUERY_INPUT]: (state, action) => {
    let calendarQueryInput = null;

    if (action.payload.currentMonthStart) {
      const monthStartMoment = moment(action.payload.currentMonthStart);
      const monthEndMoment = monthStartMoment.clone().endOf('month');

      const monthStart = moment().isBetween(
        monthStartMoment,
        monthEndMoment,
        'day',
        '[]'
      )
        ? moment().format('YYYY-MM-DD')
        : monthStartMoment.format('YYYY-MM-DD');

      const monthEnd = monthEndMoment.format('YYYY-MM-DD');

      calendarQueryInput = Object.assign({}, state.calendarQueryInput, {
        startDate: monthStart,
        endDate: monthEnd
      });
    }

    return Object.assign({}, state, {
      calendarQueryInput
    });
  }
};

// Action Creators
export function setCalendarQueryInput(currentMonthStart) {
  return {
    type: SET_CALENDAR_QUERY_INPUT,
    payload: { currentMonthStart }
  };
}

// Reducer
function reducer(state = initialState, action) {
  const handler = ACTION_HANDLERS[action.type];

  return handler ? handler(state, action) : state;
}

export default reducer;
