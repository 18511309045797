import styled from 'styled-components';
import LargestCol from 'components/LargestCol';
import { StyledPropertySelect } from '../PropertySelect';

const CalendarCol = styled(LargestCol)`
  text-align: center;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 400px;
  max-height: 100%;

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    padding-left: 1em;
    padding-right: 1em;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    ${StyledPropertySelect} {
      display: none;
    }
  }
`;

export default CalendarCol;
