import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled, { withTheme } from 'styled-components';
import {
  PROPERTY_ATMORE,
  PROPERTY_MONTGOMERY,
  PROPERTY_WETUMPKA
} from 'utils/constants';
import filter from 'lodash/filter';
import PropertyItem from './PropertyItem';

const propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.object
};

export const StyledPropertySelect = styled.div`
  position: relative;
  z-index: 10;
  margin-bottom: 1em;

  .unselected-items {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
  }
`;

class PropertySelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  toggleOpen = () => {
    this.setState({ open: !this.state.open });
  };

  selectProperty = property => {
    const { onChange } = this.props;

    onChange({ value: property, label: property });

    this.toggleOpen();
  };

  render() {
    const { value, theme } = this.props;
    const { open } = this.state;
    const items = [
      { value: PROPERTY_ATMORE, url: process.env.REACT_APP_BOOKING_URL_ATMORE },
      {
        value: PROPERTY_MONTGOMERY,
        url: process.env.REACT_APP_BOOKING_URL_MONTGOMERY
      },
      {
        value: PROPERTY_WETUMPKA,
        url: process.env.REACT_APP_BOOKING_URL_WETUMPKA
      }
    ];

    const selectedItem = filter(items, item => item.value === value);
    const unselectedItems = filter(items, item => item.value !== value);
    const topItem = selectedItem.length > 0 ? selectedItem[0] : items[0];

    return (
      <StyledPropertySelect>
        <PropertyItem
          value={topItem.value}
          isSelected={value === topItem.value}
          onClick={this.selectProperty}
        />
        <div className="unselected-items">
          {open &&
            unselectedItems.map(item => (
              <PropertyItem
                value={item.value}
                href={
                  theme.propertySelector.linkToPropertySite
                    ? item.url
                    : undefined
                }
                isSelected={value === item.value}
                onClick={
                  theme.propertySelector.linkToPropertySite && item.url
                    ? undefined
                    : this.selectProperty
                }
              />
            ))}
        </div>
      </StyledPropertySelect>
    );
  }
}

PropertySelect.propTypes = propTypes;

export default withTheme(PropertySelect);
