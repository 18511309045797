const BASE_FONT_SIZE = 14;
const MODEL_WIDTH = 1176;
const MODEL_HEIGHT = 571;
const ASPECT_RATIO = MODEL_HEIGHT / MODEL_WIDTH;
const MINIMUM_WIDTH = 0;
const MINIMUM_HEIGHT = 0;
/**
 * FONT_SIZE_RATIO_WIDTH = ([base_font_size] / [width]) * 100
 *
 * @type {number}
 */
const FONT_SIZE_RATIO_WIDTH = (BASE_FONT_SIZE / MODEL_WIDTH) * 100;
/**
 * FONT_SIZE_RATIO_HEIGHT = ([base_font_size] / [height]) * 100
 *
 * @type {number}
 */
const FONT_SIZE_RATIO_HEIGHT = (BASE_FONT_SIZE / MODEL_HEIGHT) * 100;

function calculateFontSizeWithRatio(size, ratio) {
  return (size / 100) * ratio;
}

function getFontSizeInBoundary(
  clientSize,
  minClientSize,
  fontSizeRatio,
  fontSizeMin
) {
  return clientSize >= minClientSize ? fontSizeRatio : fontSizeMin;
}

const widthFontSize = `${FONT_SIZE_RATIO_WIDTH}vw`;
const heightFontSize = `${FONT_SIZE_RATIO_HEIGHT}vh`;
const widthFontSizeMobile = '3vw';
const heightFontSizeMobile = '2vw';
const widthFontSizeMin = `${calculateFontSizeWithRatio(
  MINIMUM_WIDTH,
  FONT_SIZE_RATIO_WIDTH
)}px`;
const heightFontSizeMin = `${calculateFontSizeWithRatio(
  MINIMUM_HEIGHT,
  FONT_SIZE_RATIO_HEIGHT
)}px`;

function getFontSizeByWidth(width) {
  return getFontSizeInBoundary(
    width,
    MINIMUM_WIDTH,
    widthFontSize,
    widthFontSizeMin
  );
}

function getFontSizeByHeight(height) {
  return getFontSizeInBoundary(
    height,
    MINIMUM_HEIGHT,
    heightFontSize,
    heightFontSizeMin
  );
}

function calculateFontSize(clientWidth, clientHeight) {
  const targetMinHeight = clientWidth * ASPECT_RATIO;

  // Use mobile ratios for smaller screen sizes
  if (clientWidth < 768) {
    return clientHeight > clientWidth
      ? widthFontSizeMobile
      : heightFontSizeMobile;
  }

  return clientHeight > targetMinHeight
    ? getFontSizeByWidth(clientWidth)
    : getFontSizeByHeight(clientHeight);
}

export default function registerScalingListener(doc, win) {
  const docEl = doc.documentElement;
  const rootEl = doc.getElementById('root');

  const setFontSize = fontSize => {
    docEl.style.fontSize = fontSize;
    doc.body.style.fontSize = fontSize;
    rootEl.style.fontSize = fontSize;
    docEl.style.display = 'none';
    // Force relayout - important to new Android devices
    docEl.clientWidth; // eslint-disable-line no-unused-expressions
    docEl.style.display = '';
  };

  const handleResize = () => {
    const clientWidth = docEl.clientWidth;
    const clientHeight = docEl.clientHeight;

    if (!clientWidth) {
      return;
    }

    setFontSize(calculateFontSize(clientWidth, clientHeight));
  };

  // Abort if browser does not support addEventListener or attachEvent.
  if (!doc.addEventListener && !win.attachEvent) {
    return;
  }

  if (win.attachEvent) {
    win.attachEvent('onresize', handleResize, false);
    win.attachEvent('onload', handleResize, false);
  } else if (win.addEventListener) {
    win.addEventListener('resize', handleResize, false);
    doc.addEventListener('DOMContentLoaded', handleResize, false);
  }
}
