import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Calendar as ReactCalendar } from 'react-calendar/dist/entry.nostyle';
import moment from 'moment';
import ReactCalendarWrapper from './styled/ReactCalendarWrapper';
import SpinnerContainer from './styled/SpinnerContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DayTile from './DayTile';
import {
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT
} from 'utils/constants';

const propTypes = {
  selectedDay: PropTypes.instanceOf(Date),
  property: PropTypes.string,
  onDatesChange: PropTypes.func,
  loading: PropTypes.bool,
  activeStartDate: PropTypes.any,
  setQueryInput: PropTypes.func
};

class Calendar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      calendarDayFontSize: '1em'
    };
  }

  componentDidMount() {
    if (!document.addEventListener && !window.attachEvent) {
      return;
    }

    if (window.attachEvent) {
      window.attachEvent('onresize', this.setCalendarDayFontSize, false);
      window.attachEvent('onload', this.setCalendarDayFontSize, false);
    } else if (window.addEventListener) {
      window.addEventListener('resize', this.setCalendarDayFontSize, false);
      document.addEventListener(
        'DOMContentLoaded',
        this.setCalendarDayFontSize,
        false
      );
    }
  }

  componentWillUnmount() {
    if (window.detachEvent) {
      window.detachEvent('onresize', this.setCalendarDayFontSize, false);
      window.detachEvent('onload', this.setCalendarDayFontSize, false);
    } else if (window.removeEventListener) {
      window.removeEventListener('resize', this.setCalendarDayFontSize, false);
      document.removeEventListener(
        'DOMContentLoaded',
        this.setCalendarDayFontSize,
        false
      );
    }
  }

  setCalendarDayFontSize = () => {
    const tiles = document.getElementsByClassName('react-calendar__tile');

    this.setState({
      calendarDayFontSize:
        tiles && tiles[0] ? 0.18 * tiles[0].clientWidth + 'px' : '1em'
    });
  };

  isDaySelected = (date, selectedDay) => {
    if (!selectedDay) {
      return false;
    }

    return moment(date).isSame(selectedDay, 'day');
  };

  onActiveDateChange = ({ activeStartDate, view }) => {
    const { setQueryInput } = this.props;

    setQueryInput(activeStartDate);
  };

  renderTileContent = ({ date }) => {
    const { loading, selectedDay } = this.props;

    const isSelected = this.isDaySelected(date, selectedDay);

    const events = [
      { type: PROMOTION_EVENT, title: 'Guys Night Out' },
      { type: SPECIAL_EVENT, title: 'Dance Party' },
      { type: ENTERTAINMENT_EVENT, title: 'Krewe of Luck Hot Seats' },
      { type: ENTERTAINMENT_EVENT, title: 'Krewe of Luck Hot Seats' },
      { type: ENTERTAINMENT_EVENT, title: 'Krewe of Luck Hot Seats' }
    ];

    return <DayTile isSelected={isSelected} events={events} />;
  };

  render() {
    const {
      selectedDay,
      onDatesChange,
      loading,
      onClickDay,
      activeStartDate
    } = this.props;
    const { calendarDayFontSize } = this.state;

    const days = document.getElementsByClassName('react-calendar__tile');
    const sixWeekMonth = days && days.length > 35;

    return (
      <React.Fragment>
        <ReactCalendarWrapper
          calendarDayFontSize={calendarDayFontSize}
          sixWeekMonth={sixWeekMonth}
        >
          {loading && (
            <SpinnerContainer>
              <FontAwesomeIcon icon="spinner" size="5x" spin />
            </SpinnerContainer>
          )}
          <ReactCalendar
            minDetail="month"
            value={selectedDay}
            onChange={onDatesChange}
            onClickDay={onClickDay}
            returnValue="range"
            minDate={new Date()}
            activeStartDate={activeStartDate}
            onActiveDateChange={this.onActiveDateChange}
            calendarType="US"
            tileContent={this.renderTileContent}
          />
        </ReactCalendarWrapper>
      </React.Fragment>
    );
  }
}

Calendar.propTypes = propTypes;

export default Calendar;
