import React from 'react';
import PropTypes from 'prop-types';
import CalendarEvent from './CalendarEvent';
import styled from 'styled-components';
import { MAX_EVENTS_SHOWN } from 'utils/constants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
  events: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

const StyledList = styled.ul`
  margin: 0;
  padding: 0;
  width: 100%;
`;

const StyledMoreLink = styled.li`
  margin: 2px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.6em;

  a {
    font-size: 1em;
    font-weight: normal;
    color: ${props => props.theme.calendar.tile.dayFontColor};
    text-decoration: none;
  }

  .fa-plus {
    font-size: 0.9em;
    padding-left: 0.3em;
    margin-right: 0.3em;
  }
`;

function CalendarEventList({ events }) {
  const shownEvents = events.slice(0, MAX_EVENTS_SHOWN);

  const extraEvents = events.length - MAX_EVENTS_SHOWN;
  const pluralize = extraEvents > 1;

  return (
    <StyledList className="fa-ul">
      {shownEvents.map((event, i) => (
        <CalendarEvent
          key={`calendar-event-${i}`}
          type={event.type}
          title={event.title}
        />
      ))}
      {extraEvents > 0 && (
        <StyledMoreLink>
          <a href="#plusItems">
            <FontAwesomeIcon icon="plus" /> {extraEvents} More{' '}
            {pluralize ? 'Items' : 'Item'}
          </a>
        </StyledMoreLink>
      )}
    </StyledList>
  );
}

CalendarEventList.propTypes = propTypes;

export default CalendarEventList;
