import styled from 'styled-components';

const ReactCalendarWrapper = styled.div`
  flex: 1 1 auto;
  display: flex;
  position: relative;

  .react-calendar__month-view {
    display: flex;
    flex: 1 1 auto;
    min-height: 12.5rem;

    @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
      min-height: auto;
    }

    & > div {
      width: 100%;
    }

    & > div > div {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  .react-calendar .tile time {
    display: none;
  }

  .react-calendar .tile {
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    padding: 0.125em;
    cursor: pointer;
  }

  .react-calendar__tile {
    background: ${props => props.theme.colors.lightGrey};
    text-align: right;
    cursor: pointer;
    position: relative;
    border: 0.0625em solid ${props => props.theme.colors.white};
    height: ${props => (props.sixWeekMonth ? 16.67 : 20)}%;

    time {
      font-size: 0.7em;
      color: ${props => props.theme.calendar.tile.dayFontColor};
      position: absolute;
      top: 0.25em;
      right: 0.25em;
    }

    .content {
      text-align: center;
      font-size: ${props => props.calendarDayFontSize};
      margin-top: 0.5em;
      line-height: 0.8em;

      .rate {
        color: ${props => props.theme.calendar.tile.rateFontColor};
        font-size: 0.7em;
        font-weight: bold;
        text-align: center;

        a {
          font-size: 1em;
        }

        &.comp {
          p {
            margin-bottom: 0;
          }
        }

        p {
          font-size: 1.5em;
          margin-bottom: 0;
        }

        .unavailable {
          font-size: 0.8em;
        }
      }
    }

    &:focus {
      outline: none;
    }
  }

  .react-calendar__month-view__weekdays__weekday {
    text-transform: uppercase;
    color: ${props => props.theme.colors.black};
    font-weight: bold;
    font-size: 0.7em;
    padding: 0.75em 0;
  }

  .react-calendar__tile[disabled] .content {
    visibility: hidden;
  }

  .react-calendar__tile[disabled] time {
    color: ${props => props.theme.colors.darkGrey};
  }

  .react-calendar__navigation__label {
    text-transform: uppercase;
    font-weight: bold;
    border: 0;
    background: transparent;
    color: ${props => props.theme.colors.white};
    font-size: 1.1rem;
  }

  .react-calendar__navigation__prev2-button,
  .react-calendar__navigation__next2-button {
    display: none;
  }

  .react-calendar__navigation__prev-button,
  .react-calendar__navigation__next-button {
    background: ${props => props.theme.colors.white};
    border-radius: 0.2em;
    border: 0;
    cursor: pointer;
    padding: 0 1.2em;
    height: 100%;
    font-size: 1rem;
  }

  .react-calendar__tile--active,
  .react-calendar__tile--hasActive {
    background-color: ${props =>
      props.theme.calendar.tile.activeBackgroundColor};
  }

  .react-calendar__tile--hasActive .content .rate,
  .react-calendar__tile--active .content .rate {
    color: ${props => props.theme.colors.white};
  }

  .react-calendar__tile--hasActive time,
  .react-calendar__tile--active time {
    font-weight: bold;
    color: ${props => props.theme.colors.white};
  }

  .react-calendar__navigation {
    background-color: ${props =>
      props.theme.calendar.navigation.backgroundColor};
    padding: 0.3em;
    width: 100%;
    position: relative;
    right: 0;
    align-self: flex-end;
    height: 3.125em;
  }

  .react-calendar {
    display: flex;
    flex-direction: column;
    font-size: 0.6em;
    width: 100%;
  }

  .react-calendar__month-view__days {
    padding-left: 1em;
    padding-right: 1em;
    height: 100%;
  }

  @media screen and (min-width: ${props =>
      props.theme.breakpoints.extraSmall}) {
    .react-calendar {
      font-size: 0.75em;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    .react-calendar {
      font-size: 1em;
      min-height: ${props => props.theme.calendar.minHeight};
    }

    .react-calendar__tile time {
      font-size: 0.8em;
    }

    .react-calendar__month-view__days {
      padding: 0;
    }
  }
`;

export default ReactCalendarWrapper;
