import {
  THEME_ATMORE,
  THEME_MONTGOMERY,
  THEME_WCC,
  THEME_WETUMPKA,
  THEME_BETHLEHEM
} from 'themes';

export const PROPERTY_ATMORE = 'atmore';
export const PROPERTY_BETHLEHEM = 'bethlehem';
export const PROPERTY_MONTGOMERY = 'montgomery';
export const PROPERTY_WETUMPKA = 'wetumpka';

export const DEFAULT_PROPERTY = PROPERTY_ATMORE;

export const PROPERTIES = {
  [PROPERTY_ATMORE]: 'Atmore',
  [PROPERTY_BETHLEHEM]: 'Bethlehem',
  [PROPERTY_MONTGOMERY]: 'Montgomery',
  [PROPERTY_WETUMPKA]: 'Wetumpka'
};

export const THEME_PROPERTY_MAP = {
  [THEME_WCC]: DEFAULT_PROPERTY,
  [THEME_ATMORE]: PROPERTY_ATMORE,
  [THEME_BETHLEHEM]: PROPERTY_BETHLEHEM,
  [THEME_MONTGOMERY]: PROPERTY_MONTGOMERY,
  [THEME_WETUMPKA]: PROPERTY_WETUMPKA
};

export const PROPERTY_CODES = {
  [PROPERTY_ATMORE]: 'WCA',
  [PROPERTY_BETHLEHEM]: 'WCB',
  [PROPERTY_MONTGOMERY]: 'WCM',
  [PROPERTY_WETUMPKA]: 'WCW'
};

// Event types
export const PROMOTION_EVENT = 'promotion';
export const SPECIAL_EVENT = 'special';
export const ENTERTAINMENT_EVENT = 'entertainment';
export const MAX_EVENTS_SHOWN = 3;

// Promotion types
export const BONUS_GAME = 'bonus_game';
export const EARN_GET = 'earn_get';
export const ELECTRONIC_DRAWING = 'electronic_drawing';
export const FOOD_CREDIT = 'food_credit';
export const HOT_SEATS = 'hot_seats';
export const TICKET_DRAWING = 'ticket_drawing';
