import styled from 'styled-components';
import LargestCol from 'components/LargestCol';
import { StyledPropertySelect } from '../PropertySelect';

const EventsCol = styled(LargestCol)`
  margin-top: 1.5em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;

  & > .row {
    height: 100%;

    & > *[class*='col-'] {
      height: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  & > div {
    flex: 1 0 auto;

    &:first-child {
      flex: 0 1 auto;
    }
  }

  h5 {
    font-size: 0.9em;
  }

  div[class*='col-'].offers-form-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
  }

  ${StyledPropertySelect} {
    display: none;
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.small}) {
    h5 {
      font-size: 1.25em;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.medium}) {
    .rewards-note {
      font-size: 0.8em;
      margin-bottom: 0.5rem;
    }
  }

  @media screen and (min-width: ${props => props.theme.breakpoints.large}) {
    margin-top: 0;

    ${StyledPropertySelect} {
      display: block;
    }
  }
`;

export default EventsCol;
