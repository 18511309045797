import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import './custom-bootstrap.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { getTheme, getThemeName } from 'utils/themeUtils';
import { THEME_WCC } from './themes';
import registerScalingListener from 'utils/registerScalingListener';
import configureStore from './configureStore';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import configureApollo from './configureApollo';
import { ApolloProvider } from 'react-apollo';
import { ThemeProvider } from 'styled-components';
import AnalyticsListener from 'components/AnalyticsListener';
import GlobalStyle from './GlobalStyle';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faSpinner,
  faCalendar,
  faCircle,
  faPlus
} from '@fortawesome/free-solid-svg-icons';

library.add(faSpinner, faCalendar, faCircle, faPlus);

// Get the theme name, and if it's wcc then register scaling listener.
const themeName = getThemeName();

// Add themename class to html and body tags.
document.documentElement.classList.add(`html-theme-${themeName}`);
document.body.classList.add(`body-theme-${themeName}`);

if (themeName === THEME_WCC) {
  registerScalingListener(document, window);
}

const history = createBrowserHistory();

const store = configureStore();

// Create Apollo client.
const apolloClient = configureApollo(history);

const render = Component => {
  return ReactDOM.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Router history={history}>
          <AnalyticsListener
            trackingId={process.env.REACT_APP_GOOGLE_ANALYTICS_ID}
            themeName={themeName}
          >
            <ThemeProvider theme={getTheme(themeName)}>
              <>
                <GlobalStyle />
                <Component />
              </>
            </ThemeProvider>
          </AnalyticsListener>
        </Router>
      </Provider>
    </ApolloProvider>,
    document.getElementById('root')
  );
};

render(App);

if (module.hot) {
  module.hot.accept('./App', () => {
    const NextApp = require('./App').default;
    render(NextApp);
  });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
