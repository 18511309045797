import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import Calendar from './components/Calendar';
import { selectCalendarQueryInput } from './selectors';
import { setCalendarQueryInput } from './reducer';
import CalendarCol from './components/styled/CalendarCol';
import EventsCol from './components/styled/EventsCol';
import EventList from './components/styled/EventList';
import EventCard from './components/EventCard';
import LargestCol from 'components/LargestCol';
import { Row } from 'reactstrap';
import {
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT
} from 'utils/constants';
import styled from 'styled-components';

const propTypes = {};

const defaultProps = {};

const StyledHomePage = styled.div`
  height: 100%;

  & > .row {
    height: 100%;
  }
`;

function onDatesChange() {
  console.log('--- onDatesChange');
}

function onClickDay() {
  console.log('--- onClickDay');
}

function HomePage() {
  const calendarQueryInput = useSelector(selectCalendarQueryInput);
  const dispatch = useDispatch();

  const handleSetQueryInput = useCallback(
    activeStartDate => dispatch(setCalendarQueryInput(activeStartDate)),
    [dispatch]
  );

  const events = [
    { type: PROMOTION_EVENT, title: 'Guys Night Out' },
    { type: SPECIAL_EVENT, title: 'Dance Party' },
    { type: ENTERTAINMENT_EVENT, title: 'Krewe of Luck Hot Seats' },
    { type: ENTERTAINMENT_EVENT, title: 'Krewe of Luck Hot Seats' },
    {
      type: ENTERTAINMENT_EVENT,
      title:
        'Krewe of Luck Hot Seats With Super Long Title That Can Only Go Two Lines'
    }
  ];

  return (
    <StyledHomePage>
      <Row>
        <CalendarCol md="12" lg="7">
          <Calendar
            selectedDay={new Date()}
            onDatesChange={onDatesChange}
            onClickDay={onClickDay}
            setQueryInput={handleSetQueryInput}
            queryInput={calendarQueryInput}
            data={null}
            loading={false}
            error={false}
            property={'WCA'}
            activeStartDate={moment(calendarQueryInput.startDate).toDate()}
          />
        </CalendarCol>
        <EventsCol md="12" lg="5">
          <Row>
            <LargestCol xs="12">
              <h5>Promotions Calendar</h5>
              <p>See what's coming up at your favorite Wind Creek casino!</p>
              <EventList>
                {events.map((event, i) => (
                  <EventCard
                    key={`event-card-${i}`}
                    type={event.type}
                    title={event.title}
                  />
                ))}
              </EventList>
            </LargestCol>
          </Row>
        </EventsCol>
      </Row>
    </StyledHomePage>
  );
}

HomePage.propTypes = propTypes;
HomePage.defaultProps = defaultProps;

export default HomePage;
