import React from 'react';
import { Col } from 'reactstrap';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { getThemeName } from 'utils/themeUtils';
import { THEME_WCC } from 'themes';

const StyledCol = styled(Col)`
  @media screen and (min-width: 0px) {
    &[class*='col-'] {
      &[class*='-12'] {
        flex: 0 0 100%;
        max-width: 100%;
      }

      &[class*='-11'] {
        flex: 0 0 91.666667%;
        max-width: 91.666667%;
      }

      &[class*='-10'] {
        flex: 0 0 83.333333%;
        max-width: 83.333333%;
      }

      &[class*='-9'] {
        flex: 0 0 75%;
        max-width: 75%;
      }

      &[class*='-8'] {
        flex: 0 0 66.666667%;
        max-width: 66.666667%;
      }

      &[class*='-7'] {
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
      }

      &[class*='-6'] {
        flex: 0 0 50%;
        max-width: 50%;
      }

      &[class*='-5'] {
        flex: 0 0 41.66667%;
        max-width: 41.66667%;
      }

      &[class*='-4'] {
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
      }

      &[class*='-3'] {
        flex: 0 0 25%;
        max-width: 25%;
      }

      &[class*='-2'] {
        flex: 0 0 16.666667%;
        max-width: 16.666667%;
      }

      &[class$='-1'] {
        flex: 0 0 8.333333%;
        max-width: 8.333333%;
      }
    }
  }
`;

function LargestCol(props) {
  const { xl, lg, md, sm, xs, history, className } = props;
  const { staticContext, ...restOfProps } = props;

  const themeName = getThemeName(history.location);

  if (themeName !== THEME_WCC) {
    return <Col {...props}>{props.children}</Col>;
  }

  if (xl) {
    return (
      <StyledCol className={className || ''} xl={xl}>
        {props.children}
      </StyledCol>
    );
  } else if (lg) {
    return (
      <StyledCol className={className || ''} lg={lg}>
        {props.children}
      </StyledCol>
    );
  } else if (md) {
    return (
      <StyledCol className={className || ''} md={md}>
        {props.children}
      </StyledCol>
    );
  } else if (sm) {
    return (
      <StyledCol className={className || ''} sm={sm}>
        {props.children}
      </StyledCol>
    );
  } else if (sm) {
    return (
      <StyledCol className={className || ''} xs={xs}>
        {props.children}
      </StyledCol>
    );
  } else {
    return (
      <StyledCol className={className || ''} {...restOfProps}>
        {props.children}
      </StyledCol>
    );
  }
}

export default withRouter(LargestCol);
