import React from 'react';
import PropTypes from 'prop-types';
import HotSeats from './images/HotSeats.png';
import BonusGame from './images/BonusGame.png';
import EarnGet from './images/EarnGet.png';
import ElectricDrawing from './images/ElectricDrawing.png';
import FoodCredit from './images/FoodCredit.png';
import TicketDrawing from './images/TicketDrawing.png';
import {
  BONUS_GAME,
  HOT_SEATS,
  EARN_GET,
  ELECTRONIC_DRAWING,
  FOOD_CREDIT,
  TICKET_DRAWING
} from 'utils/constants';
import styled from 'styled-components';

const propTypes = {
  type: PropTypes.string.isRequired
};

const PROMOTION_ICON = {
  [BONUS_GAME]: BonusGame,
  [HOT_SEATS]: HotSeats,
  [EARN_GET]: EarnGet,
  [ELECTRONIC_DRAWING]: ElectricDrawing,
  [FOOD_CREDIT]: FoodCredit,
  [TICKET_DRAWING]: TicketDrawing
};

const PROMOTION_DESCRIPTION = {
  [BONUS_GAME]: 'Bonus Game',
  [HOT_SEATS]: 'Hot Seats',
  [EARN_GET]: 'Earn Get',
  [ELECTRONIC_DRAWING]: 'Electronic Drawing',
  [FOOD_CREDIT]: 'Food Credit',
  [TICKET_DRAWING]: 'Drawing'
};

const StyledPromotionType = styled.span`
  font-size: 0.7em;
  text-transform: uppercase;

  img {
    width: 2em;
  }
`;

function PromotionType({ type }) {
  return (
    <StyledPromotionType>
      <img src={PROMOTION_ICON[type]} alt={PROMOTION_DESCRIPTION[type]} />{' '}
      {PROMOTION_DESCRIPTION[type]}
    </StyledPromotionType>
  );
}

PromotionType.propTypes = propTypes;

export default PromotionType;
