import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PROPERTIES } from 'utils/constants';
import styled from 'styled-components';
import { addPropertyImagePrefix } from 'utils/images';

const propTypes = {
  value: PropTypes.string,
  href: PropTypes.string,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool
};

const StyledPropertyItem = styled.a`
  display: block;
  background: none;
  border: 0;
  border-radius: 0;
  width: 100%;
  text-align: left;
  padding: 1em;
  cursor: pointer;
  color: #ffffff;

  // Using this trick to work around issue with specificity, to avoid using !important.
  // https://www.styled-components.com/docs/advanced#issues-with-specificity
  && {
    text-decoration: none;
  }

  .fa-caret-down {
    margin-left: 0.25em;
    display: none;
  }

  strong {
    display: none;
  }

  &.selected {
    padding-top: 0.5em;
    padding-bottom: 0.5em;

    .fa-caret-down {
      display: inline-block;
    }

    strong {
      display: inline;
    }
  }

  strong,
  h1 {
    color: #fff;
    margin: 0;
  }

  img {
    width: 100%;
  }

  &.atmore {
    background: url(${addPropertyImagePrefix('atmore/Atmore-select.png')}) top
      right no-repeat;
    background-size: cover;
  }

  &.montgomery {
    background: url(${addPropertyImagePrefix(
        'montgomery/Montgomery-select.png'
      )})
      top left no-repeat;
    background-size: cover;
  }

  &.wetumpka {
    background: url(${addPropertyImagePrefix('wetumpka/Wetumpka-select.png')})
      top left no-repeat;
    background-size: cover;
  }
`;

class PropertyItem extends Component {
  handleClick = () => {
    const { value, onClick } = this.props;

    if (onClick) {
      onClick(value);
    }
  };

  render() {
    const { value, href, isSelected } = this.props;

    return (
      <StyledPropertyItem
        className={`property-item ${value} ${isSelected ? 'selected' : ''}`}
        href={href}
        target={href ? '_blank' : undefined}
        onClick={this.handleClick}
      >
        <strong>SELECT PROPERTY</strong>
        <h1>
          {PROPERTIES[value]}
          <FontAwesomeIcon icon="caret-down" />
        </h1>
      </StyledPropertyItem>
    );
  }
}

PropertyItem.propTypes = propTypes;

export default PropertyItem;
