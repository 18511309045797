import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { switchProp } from 'styled-tools';
import {
  PROMOTION_EVENT,
  SPECIAL_EVENT,
  ENTERTAINMENT_EVENT
} from 'utils/constants';

const propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

const StyledCalendarEvent = styled.li`
  margin: 2px 0;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  font-size: 0.6em;
`;

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${props =>
    switchProp('type', {
      [PROMOTION_EVENT]: props.theme.colors.promotionEvent,
      [SPECIAL_EVENT]: props.theme.colors.specialEvent,
      [ENTERTAINMENT_EVENT]: props.theme.colors.entertainmentEvent
    })}};
`;

function CalendarEvent({ title, type }) {
  return (
    <StyledCalendarEvent type={type}>
      <StyledFontAwesomeIcon icon="circle" type={type} /> {title}
    </StyledCalendarEvent>
  );
}

CalendarEvent.propTypes = propTypes;

export default CalendarEvent;
