import merge from 'lodash/merge';
import { lighten } from 'polished';

export const THEME_WCC = 'wcc';
export const THEME_WCC_RESPONSIVE = 'wcc_responsive';
export const THEME_ATMORE = 'atmore';
export const THEME_BETHLEHEM = 'bethlehem';
export const THEME_WETUMPKA = 'wetumpka';
export const THEME_MONTGOMERY = 'montgomery';

export const DEFAULT_THEME = THEME_WCC;

const colors = {
  white: '#fff',
  purple: '#ba5cd6',
  darkPurple: '#4a0a9b',
  lightBlue: '#7da3fe',
  blue: '#283278',
  darkBlue: '#122038',
  red: '#ff0000',
  green: '#1cb874',
  black: '#000',
  grey: '#eee',
  darkGrey: '#c6c6c6',
  lightGrey: '#f2f2f2',
  atmoreRed: '#a32b29',
  atmoreLightRed: '#de3636',
  atmoreDarkRed: '#811b1b',
  atmoreDarkestRed: '#4e0b0b',
  montgomeryLightBlue: '#7da3fe',
  montgomeryBlue: '#283278',
  montgomeryDarkBlue: '#192055',
  montgomeryDarkestBlue: '#0e122e',
  wetumpkaLightOrange: '#ee7522',
  wetumpkaOrange: '#ca4719',
  wetumpkaDarkOrange: '#a03512',
  wetumpkaDarkestOrange: '#782509',
  bethlehemRed: '#a32b29',
  bethlehemLightRed: '#de3636',
  bethlehemDarkRed: '#811b1b',
  bethlehemDarkestRed: '#4e0b0b',
  lightCyanBlue: '#dee2e6',
  disabledInput: '#e9ecef',
  entertainmentEvent: '#ff002d',
  promotionEvent: '#00c177',
  specialEvent: '#16aad9'
};

const breakpoints = {
  extraSmall: '400px',
  small: '576px',
  medium: '768px',
  large: '992px',
  extraLarge: '1200px',
  extraExtraLarge: '1499px'
};

const themeDefaults = {
  name: DEFAULT_THEME,
  fullWidth: '100%',
  fontFamily: "'Gotham SSm A', 'Gotham SSm B'",
  fontBold: 'bold',
  textUpperCase: 'uppercase',
  center: '0 auto',
  colors: colors,
  breakpoints: breakpoints,
  buttons: {
    primary: {
      backgroundColor: colors.purple
    },
    secondary: {
      backgroundColor: colors.green
    },
    color: colors.white,
    textUpperCase: 'uppercase',
    border: 0,
    padding: '0.25em 2em',
    block: 'block',
    inline: 'inline',
    cursor: 'pointer'
  },
  calendar: {
    tile: {
      activeBackgroundColor: colors.lightBlue,
      rateFontColor: colors.darkBlue,
      dayFontColor: colors.darkBlue,
      disabled: {
        backgroundColor: colors.lightGrey,
        dayFontColor: colors.darkGrey,
        neighboringMonth: {
          backgroundColor: colors.lightGrey,
          dayFontColor: colors.darkGrey
        }
      }
    },
    navigation: {
      backgroundColor: colors.blue
    },
    minHeight: '550px'
  },
  h5: {
    color: colors.darkPurple,
    fontSize: '0.8em'
  },
  h4: {
    fontSize: '1.1em'
  },
  arrow: {
    fontSize: '2em'
  },
  a: {
    color: colors.purple
  },
  table: {
    headerColor: colors.darkPurple,
    activeRowBackgroundColor: colors.darkPurple
  },
  forms: {
    input: {
      focusOutline: `0 0 0 0.2rem ${lighten(0.05, colors.lightBlue)}`,
      disabledBackgroundColor: '#e9ecef'
    }
  },
  modal: {
    headerColor: colors.darkPurple
  },
  propertySelector: {
    hidden: false,
    hiddenOnMobile: true,
    linkToPropertySite: true
  }
};

export default {
  // we use lodash's merge() func on each theme to override the themeDefaults with theme specific overrides recursively. Using merge instead of assign here to prevent accidental overrides/missing values.
  [THEME_WCC]: merge({}, themeDefaults, {
    name: THEME_WCC,
    buttons: {
      primary: {
        backgroundColor: colors.purple
      },
      secondary: {
        backgroundColor: colors.green
      }
    },
    calendar: {
      tile: {
        activeBackgroundColor: colors.purple,
        rateFontColor: colors.darkPurple,
        dayFontColor: colors.darkPurple
      },
      navigation: {
        backgroundColor: colors.darkPurple
      },
      minHeight: '0px'
    },
    breakpoints: {
      extraSmall: '0px',
      small: '0px',
      medium: '0px',
      large: '0px',
      extraLarge: '0px',
      extraExtraLarge: '0px'
    },
    propertySelector: {
      linkToPropertySite: false
    }
  }),
  [THEME_WCC_RESPONSIVE]: merge({}, themeDefaults, {
    name: THEME_WCC_RESPONSIVE,
    buttons: {
      primary: {
        backgroundColor: colors.purple
      },
      secondary: {
        backgroundColor: colors.green
      }
    },
    calendar: {
      tile: {
        activeBackgroundColor: colors.purple,
        rateFontColor: colors.darkPurple,
        dayFontColor: colors.darkPurple
      },
      navigation: {
        backgroundColor: colors.darkPurple
      }
    },
    propertySelector: {
      hidden: false,
      hiddenOnMobile: false,
      linkToPropertySite: false
    }
  }),
  [THEME_ATMORE]: merge({}, themeDefaults, {
    name: THEME_ATMORE,
    calendar: {
      tile: {
        activeBackgroundColor: colors.atmoreLightRed,
        rateFontColor: colors.atmoreDarkRed,
        dayFontColor: colors.atmoreDarkRed
      },
      navigation: {
        backgroundColor: colors.atmoreRed
      }
    },
    buttons: {
      primary: {
        backgroundColor: colors.atmoreRed
      },
      secondary: {
        backgroundColor: colors.atmoreDarkRed
      }
    },
    a: {
      color: colors.atmoreRed
    },
    h5: {
      color: colors.atmoreRed,
      fontSize: '0.8em'
    },
    table: {
      headerColor: colors.atmoreDarkRed,
      activeRowBackgroundColor: colors.atmoreDarkRed
    },
    forms: {
      input: {
        focusOutline: `0 0 0 0.2rem ${lighten(0.25, colors.atmoreLightRed)}`
      }
    },
    modal: {
      headerColor: colors.atmoreRed
    }
  }),
  [THEME_MONTGOMERY]: merge({}, themeDefaults, {
    name: THEME_MONTGOMERY,
    calendar: {
      tile: {
        activeBackgroundColor: colors.montgomeryLightBlue,
        rateFontColor: colors.montgomeryDarkBlue,
        dayFontColor: colors.montgomeryDarkBlue
      },
      navigation: {
        backgroundColor: colors.montgomeryBlue
      }
    },
    buttons: {
      primary: {
        backgroundColor: colors.montgomeryBlue
      },
      secondary: {
        backgroundColor: colors.montgomeryDarkBlue
      }
    },
    a: {
      color: colors.montgomeryBlue
    },
    h5: {
      color: colors.montgomeryBlue,
      fontSize: '0.8em'
    },
    table: {
      headerColor: colors.montgomeryDarkBlue,
      activeRowBackgroundColor: colors.montgomeryDarkBlue
    },
    forms: {
      input: {
        focusOutline: `0 0 0 0.2rem ${lighten(
          0.05,
          colors.montgomeryLightBlue
        )}`
      }
    },
    modal: {
      headerColor: colors.montgomeryBlue
    }
  }),
  [THEME_WETUMPKA]: merge({}, themeDefaults, {
    name: THEME_WETUMPKA,
    calendar: {
      tile: {
        activeBackgroundColor: colors.wetumpkaLightOrange,
        rateFontColor: colors.wetumpkaDarkOrange,
        dayFontColor: colors.wetumpkaDarkOrange
      },
      navigation: {
        backgroundColor: colors.wetumpkaOrange
      }
    },
    buttons: {
      primary: {
        backgroundColor: colors.wetumpkaOrange
      },
      secondary: {
        backgroundColor: colors.wetumpkaDarkOrange
      }
    },
    a: {
      color: colors.wetumpkaOrange
    },
    h5: {
      color: colors.wetumpkaOrange,
      fontSize: '0.8em'
    },
    table: {
      headerColor: colors.wetumpkaDarkOrange
    },
    forms: {
      input: {
        focusOutline: `0 0 0 0.2rem ${lighten(
          0.15,
          colors.wetumpkaLightOrange
        )}`
      }
    },
    modal: {
      headerColor: colors.wetumpkaOrange
    }
  }),
  [THEME_BETHLEHEM]: merge({}, themeDefaults, {
    name: THEME_BETHLEHEM,
    calendar: {
      tile: {
        activeBackgroundColor: colors.bethlehemLightRed,
        rateFontColor: colors.bethlehemDarkRed,
        dayFontColor: colors.bethlehemDarkRed
      },
      navigation: {
        backgroundColor: colors.bethlehemRed
      }
    },
    buttons: {
      primary: {
        backgroundColor: colors.bethlehemRed
      },
      secondary: {
        backgroundColor: colors.bethlehemDarkRed
      }
    },
    a: {
      color: colors.bethlehemRed
    },
    h5: {
      color: colors.bethlehemRed,
      fontSize: '0.8em'
    },
    table: {
      headerColor: colors.bethlehemDarkRed,
      activeRowBackgroundColor: colors.bethlehemDarkRed
    },
    forms: {
      input: {
        focusOutline: `0 0 0 0.2rem ${lighten(0.25, colors.bethlehemLightRed)}`
      }
    },
    modal: {
      headerColor: colors.bethlehemRed
    }
  })
};
