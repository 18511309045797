import React from 'react';
import PropTypes from 'prop-types';
import CalendarEventList from './CalendarEventList';

const propTypes = {
  isSelected: PropTypes.bool,
  events: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    })
  )
};

function DayTile({ isSelected, events }) {
  return (
    <div className="content">
      {events.length > 0 && <CalendarEventList events={events} />}
    </div>
  );
}

DayTile.propTypes = propTypes;

export default DayTile;
