import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  }

  // WCC.com theme does not allow vertical scrolling of body, 
  // so we set height 100%.
  .html-theme-wcc,
  body.body-theme-wcc,
  body.body-theme-wcc #root {
    height: 100%;
  }
  
  a {
    color: ${props => props.theme.a.color};
    font-weight: bold;
    text-decoration: underline;
    font-size: 0.9rem;
  }

  h5 {
    color: ${props => props.theme.h5.color};
  }
  
  h5 > a {
    float: right;
    margin-top: 0.125em;
  }
  
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-weight: bold;
  }
  
  @media screen and (max-width: 576px) {
    .modal-open .modal.error-modal .modal-dialog-centered {
      align-items: flex-start;
      padding-top: 1rem;
    }
  }
  
  /* Internet Explorer 11 Specific Rules */
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    /* Change placeholder text color */
    :-ms-input-placeholder {
      color: #757575 !important;
    }
  }
`;

export default GlobalStyle;
