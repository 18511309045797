import React from 'react';
import styled, { css } from 'styled-components';
import { Helmet } from 'react-helmet';
import { Container } from 'reactstrap';
import { Route, Switch } from 'react-router-dom';
import routes from './routes';
import { THEME_WCC } from 'themes';

const StyledApp = styled.div.attrs(props => ({
  className: `App theme-${props.theme.name}`
}))`
  padding: 0;
  overflow-x: hidden;

  // Styles for all themes except WCC
  ${props =>
    props.theme.name !== THEME_WCC &&
    css`
      overflow-y: auto;

      @media screen and (min-width: 576px) {
        padding: 2em;
      }
    `}
  
  // Styles for WCC theme
  ${props =>
    props.theme.name === THEME_WCC &&
    css`
      height: 97%;
      overflow-y: hidden;

      & > .container-fluid {
        height: 100%;
      }
    `}
`;

function App() {
  return (
    <StyledApp>
      <Helmet titleTemplate="%s | Wind Creek Promotions Calendar">
        <title>Promotions Calendar</title>
      </Helmet>
      <Container fluid>
        <Switch>
          {routes}
          <Route render={() => <div>Not Found</div>} />
        </Switch>
      </Container>
    </StyledApp>
  );
}

export default App;
